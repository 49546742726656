/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import getStore from 'Util/Store';

import { GTM_EVENT_KEY_NOT_FOUND } from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback } from '../util/wait';

/** @namespace Scandiweb/Gtm/Event/NotFound/fireNotFoundEvent */
export const fireNotFoundEvent = () => {
    const {
        events: {
            gtm_not_found
        } = {}
    } = getStore().getState().ConfigReducer.gtm;

    if (gtm_not_found) {
        const execute = debounceCallback(async () => {
            pushToDataLayer({
                event: GTM_EVENT_KEY_NOT_FOUND,
                eventAction: window.location.href,
                eventNonInteraction: 1
                // TODO: understand if label and category where required?
            });
        });

        execute();
    }
};
