/* eslint simple-import-sort/imports: off */

import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { updateMeta } from 'Store/Meta/Meta.action';
import Footer from 'Component/Footer';
import CmsPage from 'Route/CmsPage';
import { SCHEMA_ORG } from 'Util/Schema';

import {
    HomePageContainer as SourceHomePageContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceRoute/HomePage/HomePage.container';

/** @namespace Scandipwa/Route/HomePage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    cmsPage: state.CmsReducer.cmsPage,
});

/** @namespace Scandipwa/Route/HomePage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
});

/** @namespace Scandipwa/Route/HomePage/Container */
export class HomePageContainer extends SourceHomePageContainer {
    renderOrganizationSchema() {
        return createPortal(<script type="application/ld+json">{ JSON.stringify(SCHEMA_ORG) }</script>, document.head);
    }

    // Overridden to add org schema to homepage
    render() {
        return (
            <div block="HomePage">
                { this.renderOrganizationSchema() }
                <CmsPage { ...this.containerProps() } />
                <Footer isVisibleOnMobile />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageContainer);
